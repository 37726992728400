import React, { useState } from "react";
import edMantra from "../../services/edMantra";
import { toast } from "react-toastify";
import ErrorPopup from "../../Components/Errors/ErrorPopup"; // Import ErrorPopup component

const Registrations = () => {
  const initialFormData = {
    fname: "",
    mname: "",
    lname: "",
    phone: "",
    // emergencyContact: "",
    perAddress: "",
    resAddress: "",
    email: "",
    nationalId: "",
    // panNumber: "",
    dob: "",
    doj: "",
    dol: "",
    bankName: "",
    accountNum: "",
    ifsc: "",
    // branchName: "",
    departmentId: "",
    role: "",
    // reportingManager: "",
    ctc: "",
    branch: "",
    password: "",
    cpassword: "",
  };
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialFormData);
  const [reportingManagers, setReportingManagers] = useState([
    "Manager 1",
    "Manager 2",
    "Manager 3",
  ]);

  const resetForm = () => {
    setFormData(initialFormData);
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    const validationErrors = validateForm(formData);

    // Update the state of errors
    setErrors(validationErrors);

    // If there are no errors, proceed with form submission
    if (Object.keys(validationErrors).length === 0) {
      try {
        // Send the form data to your backend server for registration
        await edMantra.register(formData); // Example: replace edMantra.registerUser with your actual registration function
        toast.success("User registered successfully!");
        resetForm();
      } catch (error) {
        console.error("Error registering user:", error);
        toast.error("Failed to register user. Please try again.");
      }
    }
  };


  //-----------------validation-------------------------------------

  const validateForm = (data) => {
    let errors = {};
    if (!/^[a-zA-Z\s]+$/.test(data.fname)) {
      errors.fname = "Only Characters are allowed in First Name";
    }



    if (!/^[a-zA-Z\s]+$/.test(data.lname)) {
      errors.lname = "Only Characters are allowed in Last Name";
    }

    if (!/^\d{10}$/.test(data.phone)) {
      errors.phone = "Phone number must be exactly 10 digits long";
    }

    if (!data.perAddress) {
      errors.perAddress = "Permanent Address is required";
    }

    if (!data.resAddress) {
      errors.resAddress = "Residential Address is required";
    }

    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email address is invalid";
    }

    if (!/^\d{12}$/.test(data.nationalId)) {
      errors.nationalId = "Only 12 digits are allowed in Aadhar Card";
    }

    if (!/^\d{2}-\d{2}-\d{4}$/.test(data.dob)) {
      errors.dob = "Date of Birth must be in the format dd-mm-yyyy";
    }

    if (!/^\d{2}-\d{2}-\d{4}$/.test(data.doj)) {
      errors.doj = "Date of Joining must be in the format dd-mm-yyyy";
    }

    if (!/^\d{2}-\d{2}-\d{4}$/.test(data.dol)) {
      errors.doj = "Date of leaving must be in the format dd-mm-yyyy";
    }

    if (!/^[a-zA-Z\s]+$/.test(data.bankName)) {
      errors.bankName = "Only Characters are allowed in Bank Name";
    }
    if (!/^\d{0,18}$/.test(data.accountNum)) {
      errors.accountNum = "Account number must be a string of digits with a maximum length of 18 characters";
    }

    if (!/^[a-zA-Z0-9]{1,11}$/.test(data.ifsc)) {
      errors.ifsc = "IFSC code must be alphanumeric and have a maximum length of 11 characters";
    }


    if (!data.departmentId || data.departmentId === "") {
      errors.departmentId = "Please select a department for this user";
    }

    if (!data.role || data.role === "") {
      errors.role = "Please select a designation for this user";
    }

    if (!/^\d+$/.test(data.ctc)) {
      errors.ctc = "Only numbers are allowed in CTC";
    }
    if (!data.branch || data.branch === "") {
      errors.branch = "Please select a branch for this user";
    }

    if (data.password !== data.cpassword) {
      errors.passwordMismatch = "Passwords do not match";
    }

    if (data.password.length < 8) {
      errors.passwordLength = "Password must be at least 8 characters long";
    }

    return errors;
  };

  //------------------validations-------------------------------

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };


  return (
    <div className="container mt-1">
      {Object.keys(errors).length > 0 && <ErrorPopup errors={errors} />}
      <div className="card bg-light">
        <div className="card-body">
          <h4 className="card-title">
            <b style={{ color: "black" }}>REGISTRATION</b>
          </h4>
          <hr className="py-6" />

          <form onSubmit={handleSubmit}>
            <b style={{ color: "black" }}>Personal Information</b>
            <div className="my-4"></div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="fname"
                  placeholder="First Name"
                  value={formData.fname}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="middleName">Middle Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="mname"
                  placeholder="Middle Name"
                  value={formData.mname}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lname"
                  placeholder="Last Name"
                  value={formData.lname}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="contactnumber">Contact number</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Contact Number"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>

              {/* <div className="form-group col-md-4">
                <label htmlFor="emergencycontact">
                  Emergency Contact Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="emergencyContact"
                  placeholder="emergency contact number"
                  value={formData.emergencyContact}
                  onChange={handleChange}
                />
              </div> */}

              <div className="form-group col-md-4">
                <label>Permanent Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="perAddress"
                  placeholder="Permanent Address"
                  value={formData.perAddress}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="residentaladdress">Residental Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="resAddress"
                  placeholder="Residental Address"
                  value={formData.resAddress}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="aadharnumber">Aadhar Card Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="nationalId"
                  placeholder="Aadhar Card"
                  value={formData.nationalId}
                  onChange={handleChange}
                />
              </div>

              {/* <div className="form-group col-md-4">
                <label htmlFor="pancard">PAN Card NUmber</label>
                <input
                  type="text"
                  className="form-control"
                  id="panNumber"
                  placeholder="PAN Card Number"
                  value={formData.panNumber}
                  onChange={handleChange}
                />
              </div> */}

              <div className="form-group col-md-4">
                <label htmlFor="dob">D.O.B</label>
                <input
                  type="text"
                  className="form-control"
                  id="dob"
                  placeholder="Date Of Birth"
                  value={formData.dob}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="doj">D.O.J</label>
                <input
                  type="text"
                  className="form-control"
                  id="doj"
                  placeholder="Date Of Joining"
                  value={formData.doj}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="doj">D.O.L</label>
                <input
                  type="text"
                  className="form-control"
                  id="dol"
                  placeholder="Date Of Leaving"
                  value={formData.dol}
                  onChange={handleChange}
                />
              </div>
            </div>




            {/* ... (other form sections) ... */}

            <hr className="my-6"></hr>
            <h6 style={{ color: "black" }}>
              <b>Bank Details</b>
            </h6>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="bankName">Bank Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="bankName"
                  placeholder="Bank Name"
                  value={formData.bankName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="accountNumber">Account Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="accountNum"
                  placeholder="Account Number"
                  value={formData.accountNum}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="ifscCode">IFSC Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="ifsc"
                  placeholder="IFSC Code"
                  value={formData.ifsc}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="form-group col-md-4">
                <label htmlFor="branchName">Branch Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="branchName"
                  placeholder="Branch Name"
                  value={formData.branchName}
                  onChange={handleChange}
                />
              </div> */}
            </div>
            <hr className="my-6" />

            {/* ... (other form sections) ... */}
            <h6 style={{ color: "black" }}>
              <b>Employement Information</b>
            </h6>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="department">Department</label>
                <select class="form-select form-select-fs " id="departmentId" value={formData.departmentId} onChange={handleChange}  >
                  <option selected value="">Select</option>
                  <option selected value="finance">Finance</option>
                  <option value="recruitment">Recruitment</option>
                  <option value="sales">Sales</option>
                  <option value="training">Training</option>
                </select>

                {/* <input
                  type="text"
                  className="form-control"
                  id="departmentId"
                  placeholder="Department"
                  value={formData.departmentId}
                  onChange={handleChange}
                /> */}

              </div>

              <div className="form-group col-md-4">
                <label htmlFor="designation">Designation</label>
                <select class="form-select form-select-fs " id="role" value={formData.role} onChange={handleChange}  >
                  <option selected value="">Select</option>
                  <option selected value="AdminEM">AdminEM</option>
                  <option selected value="Counselor">Counselor</option>
                  <option value="Telecaller">Telecaller</option>
                  <option value="HrManager">HR Manager</option>
                  <option value="HrExecutive">HR Executive</option>
                  <option value="Recruiter">Recruiter</option>
                  <option value="FinanceManager">Finance Manager</option>
                  <option value="FinanceExicutive">Finance Exicutive</option>
                  <option value="Trainer">Trainer</option>
                  <option value="Tranee">Tranee</option>
                </select>

                {/* <input
                  type="text"
                  className="form-control"
                  id="role"
                  placeholder="Designation"
                  value={formData.role}
                  onChange={handleChange}
                /> */}
              </div>

              {/* <div className="form-group">
                <label htmlFor="reportingManager">Reporting Manager</label>
                <select
                  className="form-control"
                  id="reportingManager"
                  value={formData.reportingManager}
                  onChange={handleChange}
                >
                  <option value="">Select Reporting Manager</option>
                  {reportingManagers.map((manager, index) => (
                    <option key={index} value={manager}>
                      {manager}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="form-group col-md-4">
                <label htmlFor="ctc">CTC</label>
                <input
                  type="text"
                  className="form-control"
                  id="ctc"
                  placeholder="CTC"
                  value={formData.ctc}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="department">Branch</label>
                <select class="form-select form-select-fs " id="branch" value={formData.branch} onChange={handleChange} >
                  <option selected value="">Select</option>
                  <option selected value="BLY">Bareilly</option>
                  <option selected value="SJN">Shahjahanpur</option>
                  <option selected value="PBT">Pilibhit</option>
                </select>
              </div>




              <div className="form-group col-md-4">
                <label htmlFor="password">Password</label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="confirmpassword">Confirm Password</label>
                <input
                  type="text"
                  className="form-control"
                  id="cpassword"
                  placeholder="Confirm Password"
                  value={formData.cpassword}
                  onChange={handleChange}
                />
              </div>
            </div>

            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Registrations;
